import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'keycloak-groups',
});

export const createGroupRouteRef = createRouteRef({
  id: 'keycloak-groups:create',
  });

export const getGroupRouteRef = createRouteRef({
// TODO - This is a subroute, so it should be created with createSubRouteRef
// TODO - Agree on the correct route id
  id: 'keycloak-groups:get',
  params: ['groupId'],
});
