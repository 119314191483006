import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef, createGroupRouteRef, getGroupRouteRef } from './routes';

export const keycloakGroupsPlugin = createPlugin({
  id: 'keycloak-groups',
  routes: {
    root: rootRouteRef,
    get: getGroupRouteRef,
    create: createGroupRouteRef,
  },
});

export const KeycloakGroupsListPage = keycloakGroupsPlugin.provide(
  createRoutableExtension({
    name: 'KeycloakGroupsListPage',
    component: () =>
      import('./pages/GroupListPage').then(m => m.GroupList),
    mountPoint: rootRouteRef,
  }),
);

export const KeycloakGroupsCreatePage = keycloakGroupsPlugin.provide(
  createRoutableExtension({
    name: 'KeycloakGroupsCreatePage',
    component: () =>
      import('./pages/GroupCreationPage').then(m => m.GroupCreationPage),
    mountPoint: createGroupRouteRef,
  }),
);

export const KeycloakGroupDetailPage = keycloakGroupsPlugin.provide(
  createRoutableExtension({
    name: 'KeycloakGroupDetailPage',
    component: () =>
      import('./pages/GroupDetailPage').then(m => m.GroupDetailPage),
    mountPoint: getGroupRouteRef,
  }),
);
